.PopularResorts {
  .resorts-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.25rem;
    margin: 1.5rem 1.5rem;
    max-width: 1000px;
    margin: 0 auto;
    padding: 1rem;
    height: 100vh;
    align-content: flex-start;
  }

  .transparent-layer {
    background-color: rgba(0, 0, 0, 0.5);
  }

  background-image: url('https://images.unsplash.com/photo-1613420365631-c588e7cac611?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1335&q=80');
  background-repeat: repeat-y;
  background-position: center;
  background-size: cover;
  width: fit-content;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .PopularResorts {
    background-size: 500% auto;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;

    .resorts-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      width: 100%;
    }
  }
}