.units {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.TempUnitSelector, .HeightUnitSelector {
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.ToggleSwitch {
  display: inline;
}