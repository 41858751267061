.ResortForecast {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-image: url('https://images.unsplash.com/photo-1613420365631-c588e7cac611?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1335&q=80');
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  width: fit-content;
  background-size: cover;
  margin: 0 auto;

  .transparent-layer {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .Resort {
    display: flex;
    border: 1px solid skyblue;
    border-radius: 5px;
    padding: 1rem;
    justify-content: space-between;
    flex-direction: column;
    width: fit-content;
    box-shadow: 5px 5px 5px black;

    .snow, .temp {
      align-items: center;
    }
  }

  .units {
    align-self: flex-start;
  }

  .resort-forecast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80vw;
    height: 100vh;
    padding-top: 20px;
  }

  .weather-icon {
    display: flex;
    justify-content: center;
  }

  .hourly-snowfall {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    background-color: rgba(135, 206, 250, 0.8);
    width: fit-content;
    border: 1px solid lightskyblue;
    border-radius: 10px;
    margin-top: 50px;
    color: navy;
    box-shadow: 5px 5px 5px black;
    padding: 0 20px;

    @media screen and (max-width: 820px) {
      width: 80vw;
    }

    >p {
      margin: 0;
      width: calc(100% + 40px);
      background-color: rgb(121 206 255);
      text-align: center;
      padding: 10px 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 1.25rem;
    }

    .hour {
      font-weight: bold;
      text-align: center;
    }

    .hour-snowfall {
      text-align: center;
    }
  }

  .each-hour-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    margin: 0 1rem;
    width: 100%;
  }
}

.Search {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .ResortForecast {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .ResortForecast .resort-forecast-container {
    width: 100%;
  }
}