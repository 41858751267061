* {
  box-sizing: border-box;
  // border: 1px solid red;
}

body {
  margin: 0;
  background-color: white;
}

a, .link {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 600px) {
  .App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}