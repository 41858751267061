.SearchResult {
  display: flex;
  justify-content: center;
  border-top: 1px solid lightgrey;
  padding: 0 1rem;

  a {
    color: black;
  }
}

.SearchResult:hover {
  background-color: lightskyblue;
}

.SearchResult:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}