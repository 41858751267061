.Navigation {
  display: flex;
  justify-content: space-evenly;
  background-color: lightskyblue;
  padding: 0.75rem;
  
  .page-nav {
    display: flex;
    gap: 1.5rem;
  }

  .user-nav {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .link:hover {
    cursor: pointer;
  }

  p {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .Navivation {
    min-width: 100vw;
  }
}