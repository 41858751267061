.Resort {
  background-color: rgb(214, 247, 254, 0.9);
  border: 1px solid skyblue;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 5px 5px 5px black;

  p {
    margin: 0;
  }

  .name-flag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, lightskyblue, rgb(200, 234, 255));
    padding: 0 1rem;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    margin: -1rem -1rem 0 -1rem;
    color: white;
  
    .flag {
      color: rgb(107, 193, 247);
      font-size: 2rem;
    }
  
    p {
      font-size: 1.25rem;
      margin: 0;
    }
  }

  .forecast-details {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    p {
      margin: 0;
    }
  
    
    .snow-temp {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      
      .snow {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }
    
    .temp-num-unit, .snow-num-unit {
      display: flex;
      align-items: center;
    }
    
    .snow-num-unit {
      border: 1px solid black;
    }
  }
  
  .current-weather {
    display: flex;
    justify-content: center;
  }
  
  .snow-today, .snow-8-day {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }
  
  .heading {
    text-align: center;
    font-weight: bold;
  }
  
  .temp {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .temp-icon {
    width: 3rem;
  }

  .snow-icon {
    width: 3rem;
  }

  span {
    color: black;
  }

  .temp-num {
    font-size: 2rem;
  }

  .weather-icon {
    width: 7rem;
  }

  .weather-icon-status {
    display: flex;
    flex-direction: column;
  }
}
