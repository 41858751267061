.Search {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.search-box {
  display: flex;
  justify-content: center;
  width: 100%;
}

input {
  width: fit-content;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  border: none;
}

input:focus {
  outline: none;
}

button {
  height: 50px;
  width: 100px;
  display: none;
}

.search-container {
  width: fit-content;
  height: fit-content;
  border-radius: 25px;
  font-size: 1.5rem;
  text-align: center;
  border-style: solid;
  outline: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.search-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
}

.Search {
  form {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      width: 2rem;
    }
  }

  padding-top: 20px;
}

.fa-magnifying-glass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.5rem;
  width: 2rem;
}

@media screen and (max-width: 600px) {
  .Search {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-box {
    width: 100%;
  }

  .search-box input {
    width: 80%;
    margin-bottom: 0;
  }

  .search-box form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}