.Header {
  display: flex;
  justify-content: center;
  align-items:center;
  gap: 20px;

  .logo {
    width: 50px;
    height: 50px;
  }

  .title {
    color: rgb(143, 221, 252);
  }

  .logo:hover, .title:hover {
    cursor: pointer;
  }
}
